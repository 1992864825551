import fullWidth from "../layouts/fullWidth";
const routes = [
  {
    path: "/",
    redirect: "/chat/:church/:user",
    component: fullWidth,
    meta: { requireAuthentication: false },
    children: [
      {
        path: "/chat/:church/:user",
        name: "dashboard",

        component: () => import("../views/users/chat"),
      },
    ],
  },
];

export default routes;

<template>
	<nav
		class="
			navbar navbar-vertical
			fixed-left
			navbar-expand-md navbar-light
			bg-white
		"
		id="sidenav-main"
	>
		<div class="container-fluid">
			<!--Toggler-->
			<navbar-toggle-button @click.native="showSidebar">
			</navbar-toggle-button>
			<router-link class="navbar-brand" to="/">
				<img :src="logo" class="navbar-brand-img" alt="..." />
			</router-link>

			<slot name="mobile-right">
				<ul class="nav align-items-center d-md-none login-details">
					
					<base-dropdown
						class="nav-item"
						menu-on-right
						tag="li"
						title-tag="a"
						v-if="activeUserInfo"
					>
						<a
							slot="title-container"
							class="nav-link"
							href="#"
							role="button"
						>
							<div class="media align-items-center">
								<span class="avatar avatar-sm rounded-circle">
									<img
										alt="Image placeholder"
										:src="activeUserInfo.profile_image"
									/>
								</span>
							</div>
						</a>

						<div class="dropdown-header noti-title">
							<h6 class="text-overflow m-0">Welcome!</h6>
						</div>
						<b-dropdown-item @click="goToProfile">
							<i class="ni ni-single-02"></i>
							<span>My profile</span>
						</b-dropdown-item>
						<div class="dropdown-divider"></div>
					
						<b-dropdown-item @click="logOutUser">
							<i class="ni ni-user-run"></i>
							<span>Logout</span>
						</b-dropdown-item>
					</base-dropdown>
				</ul>
			</slot>
			<slot></slot>
			<div
				v-show="$sidebar.showSidebar"
				class="navbar-collapse collapse show"
				id="sidenav-collapse-main"
			>
				<div class="navbar-collapse-header d-md-none">
					<div class="row">
						<div class="col-6 collapse-brand">
							<router-link to="/">
								<img :src="logo" />
							</router-link>
						</div>
						<div class="col-6 collapse-close">
							<navbar-toggle-button
								@click.native="closeSidebar"
							></navbar-toggle-button>
						</div>
					</div>
				</div>

				<ul class="navbar-nav">
					<slot name="links"> </slot>
				</ul>
				<!--Divider-->
				<hr class="my-3" />
				<!--Heading-->
			</div>
		</div>
	</nav>
</template>
<script>
	import NavbarToggleButton from "@/components/NavbarToggleButton";

	export default {
		name: "sidebar",
		components: {
			NavbarToggleButton,
		},

		computed: {
			activeUserInfo() {
				return this.$store.state.authentication.user;
			},
		},
		props: {
			logo: {
				type: String,
				default: require("../../assets/images/green.png"),
				description: "Sidebar app logo",
			},
			autoClose: {
				type: Boolean,
				default: true,
				description:
					"Whether sidebar should autoclose on mobile when clicking an item",
			},
		},
		provide() {
			return {
				autoClose: this.autoClose,
			};
		},
		methods: {
			closeSidebar() {
				this.$sidebar.displaySidebar(false);
			},
			showSidebar() {
				this.$sidebar.displaySidebar(true);
			},

			logOutUser() {
				this.$helper.showLoading();
				this.$store.dispatch("logOut").then(() => {
					this.$helper.hideLoading();
					this.$helper.showSuccessMessage(
						this,
						"You are successfully logged out!"
					);
					this.$router.replace("/login").catch((error) => {
						this.$helper.hideLoading();
						this.$helper.showErrorMessage(this, error);
					});
				});
			},
			goToProfile() {
				this.$router.replace("/account/profile");
			},
		},

		beforeDestroy() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.showSidebar = false;
			}
		},
	};
</script>

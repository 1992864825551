import Vue from "vue";
import Plugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import "material-icons/iconfont/material-icons.css";
import Toasted from "vue-toasted";
import Vuesax from "vuesax";
import lodash from "lodash";
import FeatherIcon from "./components/custom/FeatherIcon.vue";
import VxTooltip from "./components/custom/vx-tooltip/VxTooltip.vue";
import CKEditor from "ckeditor4-vue";

import router from "./router";
import store from "./store";

import helper from "./helper.js";
Vue.prototype.$helper = helper;

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;

// responsive scss
import "./assets/css/responsive.scss";

// plugin setup
Vue.use(Plugin);
import "vuesax/dist/vuesax.css";
import VueChatScroll from "vue-chat-scroll";

Vue.use(VueChatScroll);
Vue.use(Vuesax);
Vue.use(lodash);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(VxTooltip.name, VxTooltip);
Vue.use(CKEditor);
Vue.use(Toasted, { duration: 2000, position: "bottom-right" });

Vue.config.productionTip = false;

// require("@/store/modules/checkAuthorization");
// store.dispatch("attempt", localStorage.getItem("token"), 0); // access token

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
